import React, { useState, useEffect } from 'react'
import Header from 'components/common/Header'
import CloseButton from 'components/common/CloseButton'
import TaskTotalIcon from 'components/common/TaskTotalIcon'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  createTaskAssignment,
  deleteTaskAssignment,
} from 'store/taskAssignment/actions'
import {
  fetchOrdersPowerPickStart,
  updateOrdersStart,
  resetOrders,
  validateClaim,
  validateStart,
} from 'store/order/actions'
import { resetModals, showModal } from 'store/modal/actions'
import OrderListItem from './OrderListItem'
import { TASK_TYPES } from 'constants/taskAssignment'
import { LOADING_PAGE } from 'constants/routes'
import { isEmptyObject, redirect } from 'helpers/index'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import {
  ORDER_LOAD_STATUSES,
  LOADING_PAGE_STATUSES,
} from '../../../../../constants/order'
import { ROLES } from 'constants/roles'
import MenuIcon from '@material-ui/icons/Menu'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core'
import { CARRIER_GROUPS } from '../../../../../constants/carriers'
import { AllInclusive } from '@material-ui/icons'
import { isEqualObjects } from 'helpers/index'
import * as query from 'querystringify'
import Pickup from '../../../../../assets/svg/carrier-pickup.svg'
import Abf from '../../../../../assets/svg/carrier-abf.svg'
import Xpo from '../../../../../assets/svg/carrier-xpo.svg'
import Delivery from '../../../../../assets/svg/carrier-delivery.svg'
import Landstar from '../../../../../assets/svg/carrier-landstar.svg'
import Cfi from '../../../../../assets/svg/carrier-cfi.svg'
import TForce from '../../../../../assets/svg/carrier-tforce.svg'
import Fedex from '../../../../../assets/svg/carrier-fedex.svg'
import Ltl from '../../../../../assets/svg/carrier-ltl.svg'
import Ftl from '../../../../../assets/svg/carrier-ftl.svg'
import { ORDERS_TO_LOAD_LIST_PAGE } from '../../../../../constants/routes'
const OrdersToLoadListPage = (props) => {
  const {
    orderProcessList = [],
    resetModals,
    showModal,
    updateOrders,
    fetchOrdersToLoad,
    createTaskAssignment,
    deleteTaskAssignment,
    user,
    homeAnalytics,
    resetOrders,
    isLoading,
    validateClaim,
    validateStart,
  } = props

  const [selectedItem, setSelectedItem] = useState({})

  const onLeave = () => {
    resetOrders()
  }

  const onUnclaim = (item) => {
    item.unclaim = true
    setSelectedItem(item)
  }

  const unassignFromOrder = (
    processId,
    orderLoadStatusId,
    taskAssignmentId,
  ) => {
    updateOrders({
      items: [
        {
          id: processId,
          order_load_status_id: orderLoadStatusId,
          order_load_status: ORDER_LOAD_STATUSES.STATUS_QUEUE,
        },
      ],
    })
    deleteTaskAssignment({
      request: {
        id: taskAssignmentId,
      },
      afterAction: () => {
        fetchOrdersToLoad({
          params: {
            'filters[carriers]': getSelectedCarriers(),
            warehouse_staff_id: user.warehouseStaffId,
            'filters[order_statuses]': LOADING_PAGE_STATUSES,
            task_type_id: TASK_TYPES.LOADING_TYPE,
          },
        })
      },
    })
  }

  const onChangeOrdersList = () => {
    if (orderProcessList.length > 0 || !isLoading) {
      resetModals()
    }
  }

  const assignToOrder = (processId, orderLoadStatusId, taskId) => {
    updateOrders({
      items: [
        {
          id: processId,
          order_load_status_id: orderLoadStatusId,
          order_load_status: ORDER_LOAD_STATUSES.STATUS_ASSIGNED,
        },
      ],
    })
    createTaskAssignment({
      request: {
        data: {
          task_id: taskId,
          warehouse_staff_id: user.warehouseStaffId,
          role_id: ROLES.LOADING_WORKER,
        },
      },
      afterAction: () => {
        fetchOrdersToLoad({
          params: {
            'filters[carriers]': getSelectedCarriers(),
            warehouse_staff_id: user.warehouseStaffId,
            'filters[order_statuses]': LOADING_PAGE_STATUSES,
            task_type_id: TASK_TYPES.LOADING_TYPE,
          },
          type: 'today',
        })
      },
    })
  }

  const onValidateStartAfterAction = (data) => {
    if (data.success) {
      redirect(LOADING_PAGE.replace(':id', selectedItem.taskAssignment.id))
    } else {
      resetModals()

      showModal({
        name: 'modalWarning',
        text: data.message,
        onAccept: onLoad,
        onDeny: onLoad,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  const onValidateClaimAfterAction = (data) => {
    if (data.success) {
      assignToOrder(
        selectedItem.id,
        selectedItem.entity.orderLoadStatus.id,
        selectedItem.task.id,
      )
    } else {
      resetModals()

      showModal({
        name: 'modalWarning',
        text: data.message,
        onAccept: onLoad,
        onDeny: onLoad,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  const onChangeSelectedTask = () => {
    if (isEmptyObject(selectedItem)) {
      return
    }

    showModal({
      name: 'modalPending',
    })

    if (selectedItem.taskAssignment.id) {
      if (
        selectedItem.entity.orderLoadStatus.id ===
        ORDER_LOAD_STATUSES.STATUS_LOADING
      ) {
        redirect(LOADING_PAGE.replace(':id', selectedItem.taskAssignment.id))
      } else {
        if (selectedItem.unclaim) {
          unassignFromOrder(
            selectedItem.id,
            selectedItem.entity.orderLoadStatus.id,
            selectedItem.taskAssignment.id,
          )
        } else {
          validateStart({
            request: {
              data: {
                task_id: selectedItem.task.id,
                task_assignment_id: selectedItem.taskAssignment.id,
                orders_id: selectedItem.entity.order.orders_id,
              },
            },
            afterAction: onValidateStartAfterAction,
          })
        }
      }
    } else {
      validateClaim({
        request: {
          data: {
            orders_id: selectedItem.entity.order.orders_id,
            task_type_id: TASK_TYPES.LOADING_TYPE,
          },
        },
        afterAction: onValidateClaimAfterAction,
      })
    }
  }

  const onLoad = () => {
    setTimeout(() => {
      showModal({
        name: 'modalPending',
      })

      fetchOrdersToLoad({
        params: {
          'filters[carriers]': getSelectedCarriers(),
          warehouse_staff_id: user.warehouseStaffId,
          'filters[order_statuses]': LOADING_PAGE_STATUSES,
          task_type_id: TASK_TYPES.LOADING_TYPE,
        },
      })
    }, 100)

    return onLeave()
  }

  const getSelectedCarriers = () => {
    const params = query.parse(window.location.search)

    return params.carriers
      ? params.carriers.split(',').map((param) => parseInt(param))
      : CARRIER_GROUPS.ALL_CARRIERS_FILTER
  }

  const filters = getSelectedCarriers()

  const TABS = [
    {
      id: 1,
      label: 'All',
      filters: CARRIER_GROUPS.ALL_CARRIERS_FILTER,
      icon: <AllInclusive />,
    },
    {
      id: 2,
      label: 'FedEx',
      filters: CARRIER_GROUPS.FEDEX_FILTER,
      icon: <Fedex />,
    },
    {
      id: 3,
      label: 'TForce',
      filters: CARRIER_GROUPS.TFORCE_FILTER,
      icon: <TForce />,
    },
    {
      id: 4,
      label: 'ABF',
      filters: CARRIER_GROUPS.ABF_FILTER,
      icon: <Abf />,
    },
    {
      id: 5,
      label: 'XPO',
      filters: CARRIER_GROUPS.XPO_FILTER,
      icon: <Xpo />,
    },
    {
      id: 6,
      label: 'Spirit Delivery',
      filters: CARRIER_GROUPS.DELIVERY_FILTER,
      icon: <Delivery />,
    },
    {
      id: 7,
      label: 'Pickup',
      filters: CARRIER_GROUPS.PICKUP_FILTER,
      icon: <Pickup />,
    },
    {
      id: 8,
      label: 'Landstar',
      filters: CARRIER_GROUPS.LANDSTAR_FILTER,
      icon: <Landstar />,
    },
    {
      id: 9,
      label: 'CFI',
      filters: CARRIER_GROUPS.CFI_FILTER,
      icon: <Cfi />,
    },
    {
      id: 10,
      label: 'FTL Other',
      filters: CARRIER_GROUPS.FTL_OTHER_FILTER,
      icon: <Ftl />,
    },
    {
      id: 11,
      label: 'LTL Other',
      filters: CARRIER_GROUPS.LTL_OTHER_FILTER,
      icon: <Ltl />,
    },
  ]

  useEffect(onLoad, [])
  useEffect(onChangeOrdersList, [orderProcessList])
  useEffect(onChangeSelectedTask, [selectedItem, setSelectedItem])

  const calcCurrentFilter = () => {
    let selectedFilterLabel = 'All' // Default to 'All'
    TABS.forEach((tab) => {
      if (isEqualObjects(filters, tab.filters)) {
        selectedFilterLabel = tab.label
      }
    })
    return selectedFilterLabel
  }

  const [currentFilter, setCurrentFilter] = useState(calcCurrentFilter())

  const onSelect = (item) => {
    setSelectedItem(item)
  }

  const [drawerOpen, setDrawerOpen] = useState(false)
  const toggleDrawerOpen = (open) => (event) => {
    setDrawerOpen(open)
  }

  const groupFilterClick = (e) => {
    const index = e.currentTarget.getAttribute('index')
    onFilterChange(TABS[index].filters)
    setCurrentFilter(TABS[index].label)
    setDrawerOpen(false)
  }

  const showModalOrderNotes = (item) => {
    showModal({
      name: 'modalOrderNotes',
      onClose: resetModals,
      order: item.entity.order,
    })
  }

  const onFilterChange = (filterList) => {
    showModal({ name: 'modalPending' })
    fetchOrdersToLoad({
      params: {
        'filters[carriers]': filterList,
        warehouse_staff_id: user.warehouseStaffId,
        'filters[order_statuses]': LOADING_PAGE_STATUSES,
        task_type_id: TASK_TYPES.LOADING_TYPE,
      },
    })
    redirect(`${ORDERS_TO_LOAD_LIST_PAGE}?carriers=${filterList.join(',')}`)
  }

  const [numberOfReturnedItems, setNumberOfReturnedItems] = useState(
    orderProcessList.length,
  ) // TODO - alter API so meta includes "numberFound", and use that here... so it won't use items.length, but rather the meta.numberFound for this page
  if (numberOfReturnedItems !== orderProcessList.length) {
    setNumberOfReturnedItems(orderProcessList.length)
  }

  return (
    <div className="tasks-page">
      <Header>
        <TaskTotalIcon count={homeAnalytics.loadingCount} />
        <div className="title">Orders To Load</div> <CloseButton />
      </Header>
      <div className="content">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MenuIcon
            fontSize={'large'}
            onClick={toggleDrawerOpen(true)}
            style={{ marginLeft: '2%' }}
          />
          {currentFilter}
          <span style={{ marginLeft: '5px', fontSize: 'x-small' }}>
            [ {numberOfReturnedItems} ]
          </span>
        </div>
        <Drawer
          anchor={'left'}
          open={drawerOpen}
          onClose={toggleDrawerOpen(false)}
        >
          <span style={{ textAlign: 'center', marginTop: '5%' }}>
            Filter Carriers
          </span>

          <Divider />
          <List>
            {TABS.map((item, index) => {
              const { label, icon, id } = item
              return (
                <ListItem
                  button
                  key={id}
                  index={index}
                  onClick={(e) => {
                    groupFilterClick(e)
                  }}
                >
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText primary={label} />
                </ListItem>
              )
            })}
          </List>
        </Drawer>
        {orderProcessList.length > 0 ? (
          <List>
            {orderProcessList.map((item, index) => (
              <OrderListItem
                key={item.id}
                index={index}
                item={item}
                itemId={item.id}
                onSelect={onSelect}
                showModalOrderNotes={showModalOrderNotes}
                onUnclaim={onUnclaim}
              />
            ))}
          </List>
        ) : (
          <div className="no-tasks">
            <div className="no-tasks__text">No Orders</div>
          </div>
        )}
      </div>
    </div>
  )
}

OrdersToLoadListPage.propTypes = {
  orderProcessList: PropTypes.array,
}

const mapStateToProps = (state) => ({
  orderProcessList: state.order.powerPickOrders,
  homeAnalytics: state.homeAnalyticsReducer,
  isLoading: state.order.isLoading,
})

const mapDispatchToProps = {
  fetchOrdersToLoad: fetchOrdersPowerPickStart,
  showModal,
  resetModals,
  validateClaim,
  validateStart,
  createTaskAssignment,
  deleteTaskAssignment,
  updateOrders: updateOrdersStart,
  resetOrders,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersToLoadListPage)
